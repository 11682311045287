import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <main className="page">
        <section className="contact-page">
          <article className="contact-info">
            <h3>Želite li stupiti u kontakt?</h3>
            <p>
            Obratite nam se s povjerenjem, a mi ćemo se potruditi da Vam odgovorimo u najkraćem roku.
            </p>
            <p>
            Zbog specifičnosti posla molili bi Vas da nam se obraćate isključivo e-mailom, kako ne bi ometali savjetovanja u tijeku.
            </p>
            <div className="social-contact">
            <p><strong>Potraži nas na društvenim mrežama</strong></p>
            <p>
            <div className="introImg">
                <div className="image-main lotusShadow">
                <StaticImage
                  src="../assets/images/lotus1.png"           
                  id="lotus1"
                  alt="Apsiha"
                  placeholder="BLURRED"
                  //layout="constrained"
                ></StaticImage>
                </div>
                <div className="image-hover lotusShadow">
                  <StaticImage
                  src="../assets/images/lotus2.png"            
                  id="lotus2"
                  alt="Apsiha"
                  placeholder="BLURRED"
                  //layout="constrained"
                ></StaticImage>
                </div>
                <div className="social-menu">
                    <ul>
                      <li><a href="https://www.facebook.com/apsiha/" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faFacebook} /></a></li>
                      <li><a href="https://www.instagram.com" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faInstagram} /></a></li>
                      <li><a href="https://www.youtube.com/channel/UC9p9gtmH4N4W5FESQ1_z95g" target="blank" rel="noreferrer"><FontAwesomeIcon className="fab" icon={faYoutube} /></a></li>
                    </ul>
                  </div>
                </div>
                </p>
                <br/>
            <p>Kliknite na ikonu i posjetite našu Facebook stranicu, Instagram ili Youtube kanal i priključite se kako bi pratili zanimljivosti i aktualne informacije vezane uz rad Centra.</p>
            </div>
          </article>
          <article>
            <h4>Pošalji upit</h4>
            <form className="form contact-form" action="../api/contact.php" method='post' name="sentMessage" id="contactForm" novalidate >
              <div className="form-row">
                <label htmlFor="name">Ime</label>
                <input type="text" name="name" id="name" className="form-control" placeholder="Vaše Ime *" required data-validation-required-message="Please enter your name."/>
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-row">
                <label htmlFor="email">email</label>
                <input type="email" name="email" id="email" className="form-control" placeholder="Vaš email *" required data-validation-required-message="Please enter your email address."/>
                <p className="help-block text-danger"></p>
              </div>
              <div className="form-row">
                <label htmlFor="message">Poruka</label>
                <textarea name="message" id="message" className="form-control" placeholder="Vaša poruka *" required data-validation-required-message="Please enter a message."></textarea >
                <p className="help-block text-danger"></p>
              </div>
              <div className="clearfix"></div>
              <div id="success"></div>
              <button type="submit" name="contactSubmit" className="btn block">
                Pošalji
              </button>
            </form>
            <div className="dropdown-menu info-link">
                  <input type="checkbox" style={{ opacity: 0, position:'absolute', left:'-9999px'}} id="apsihaInfo" />
                  <label htmlFor="apsihaInfo">Apsiha j.d.o.o. Novi Zagreb</label>
                  <div className="info-content">
                      <ul>
                        <li> Apsiha j.d.o.o. </li>
                        <li> Adresa sjedišta: Ulica Karela Zahradnika 21, 10020 Novi Zagreb </li>
                        <li> Osnivači: Ana Miletić (direktor) i Ivana Mrgan </li>
                        <li> Osnovano: 2016. godine pri Trgovačkom sudu u Zagrebu </li>
                        <li> Temeljni kapital: 10,00 kn </li>
                        <li> OIB: 88717880071 </li>
                        <li> MB: 04558693 </li>
                        <li> Bankovni račun (OTP): HR4424070001100447286 </li>
                      </ul>
                  </div>
                </div>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export default Contact
